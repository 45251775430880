import { getDayTrending, getVideos } from './api.js';
import * as basicLightbox from 'basiclightbox';
import black from '../../src/images/hero/hero-desktop-1x.png';
import white from '../../src/images/hero/hero-white-desktop-2x.png';
import sprite from '../images/sprite.svg';
// import hall from '../../src/images/library/hero-hall-desktop-1x.jpg';
// import people from '../../src/images/hero/hero-people-desktop-1x.jpg';

const hero = document.querySelector('.hero');
const heroLib = document.querySelector('.herolib');

const LightSwitcher = document.querySelector('.switcher');
const url = window.location.href;
// const people = hero.style.backgroundImage;
// hero.style.backgroundImage = `url(${people})`;

if (url.includes('library')) {
  hero.innerHTML = '';
  // hero.style.backgroundImage = `url(${hall})`;
  hero.classList.remove('hero');

  const libMurkup = `
    <div class="hero-wrap__content">
      <h2 class="title">Create Your Dream Cinema</h2>

      <p class="description">
        Is a guide to designing a personalized movie theater experience with the right equipment, customized decor, and favorite films. This guide helps you bring the cinema experience into your own home with cozy seating, dim lighting, and movie theater snacks.
      </p>
      <a href="./catalog.html" class="watch-trailer__btn">Get Started</a>
    </div>
 
  `;
  hero.innerHTML = libMurkup;
}

LightSwitcher.addEventListener('click', switchPhoto);

function switchPhoto() {
  const blackImage = document.querySelector('.black');
  const currentImageSrc = blackImage.getAttribute('src');
  const newImageSrc = currentImageSrc === black ? white : black;
  blackImage.setAttribute('src', newImageSrc);

  localStorage.setItem('imageColor', newImageSrc);
}

getDayTrending(1).then(({ results }) => {
  hero.innerHTML = '';
  const filteredResult = results.filter(
    res =>
      (res.title?.length < 20 || res.name?.length < 20) &&
      res.backdrop_path &&
      res.overview.length < 200
  );
  const random = Math.floor(Math.random() * (filteredResult.length - 1));
  const movieOfDay = filteredResult[random];
  createTrendingMarkup(movieOfDay);

  const trailerBtn = hero.querySelector('#trailer-btn');
  trailerBtn.addEventListener('click', onBtnClick);

  function onBtnClick(params) {
    getVideos(movieOfDay.id)
      .then(videos => {
        const infoTr = videos.find(el => el.name === 'Official Trailer');
        const keyTr = infoTr.key;

        const closeButton = `<button id="close-btn"  class="modal__close"><svg class="modal__svg" width="10" height="10"><use width="10" height="10" class="svg__use" href="${sprite}#icon-close2"></use></svg></button>`;

        const instance = basicLightbox.create(`
          <div>
             ${closeButton}
            <iframe class="iframe" src="https://www.youtube.com/embed/${keyTr}" width="560" height="315" frameborder="0"></iframe>
          </div>
        `);

        instance.show(() => {
          const closeBtn = document.getElementById('close-btn');
          closeBtn.addEventListener('click', () => {
            instance.close(() => {
              document.body.style.overflow = '';
              document.removeEventListener('keydown', handleKeyDown);
            });
          });
          document.addEventListener('keydown', handleKeyDown);
          document.body.style.overflow = 'hidden';
        });

        instance.element().addEventListener('click', event => {
          if (event.target === instance.element()) {
            instance.close(() => {
              document.body.style.overflow = '';
              document.removeEventListener('keydown', handleKeyDown);
            });
          }
        });

        function handleKeyDown(event) {
          if (event.key === 'Escape') {
            instance.close(() => {
              document.body.style.overflow = '';
              document.removeEventListener('keydown', handleKeyDown);
            });
          }
        }
      })
      .catch(error => {
        const closeButton = `<button id="close-btn"  class="modal__close"><svg class="modal__svg" width="10" height="10"><use width="10" height="10" class="svg__use" href="${sprite}#icon-close2"></use></svg></button>`;

        const instance = basicLightbox.create(`
          <div>
            ${closeButton}
            <div class="notification-trailer-fail">
              <p class="notification-trailer-fail-text">OOPS...<br/> We are very sorry!<br /> But we couldn’t find the trailer.</p>
              <div class="bg-box"></div>
            </div>
          </div>
        `);

        instance.show(() => {
          const closeBtn = document.getElementById('close-btn');
          closeBtn.addEventListener('click', () => {
            instance.close(() => {
              document.body.style.overflow = '';
              document.removeEventListener('keydown', handleKeyDown);
            });
          });
          document.addEventListener('keydown', handleKeyDown);
          document.body.style.overflow = 'hidden';
        });

        instance.element().addEventListener('click', event => {
          if (event.target === instance.element()) {
            instance.close(() => {
              document.body.style.overflow = '';
              document.removeEventListener('keydown', handleKeyDown);
            });
          }
        });

        function handleKeyDown(event) {
          if (event.key === 'Escape') {
            instance.close(() => {
              document.body.style.overflow = '';
              document.removeEventListener('keydown', handleKeyDown);
            });
            document.removeEventListener('keydown', handleKeyDown);
          }
        }
      });
  }

  // -----------------------------------------
});

function createTrendingMarkup(movieOfDay) {
  hero.style.backgroundImage = `url(https://image.tmdb.org/t/p/original${movieOfDay.backdrop_path})`;
  const markup = `
           <span class="black-span">
          
           
            <div class="hero-wrap__content">
                    <h2 class="title">${
                      movieOfDay.title || movieOfDay.name
                    }</h2>
                    <div class="catalog__stars-wrap-hero">
                        <div class="catalog__rating-active-hero"
                        style="width:${
                          movieOfDay.vote_average / 2 / 0.05
                        }%"></div>
                    </div>
                    <p class="description">${
                      movieOfDay.overview.slice(0, 215) + '...'
                    }</p>
                    <button class="watch-trailer__btn" id="trailer-btn" data-btn="trailer-fail" >Watch trailer</button>
                </div>
                </span>
        
    `;
  hero.innerHTML = markup;
}
